import { GoogleAdsActionTypes } from '@context/google-ads/actions';
import { GoogleAdsActionsEnum, GoogleAdsState } from '@context/google-ads/types';

export const initialGoogleAdsState: GoogleAdsState = { isEmpty: true, isLoading: false };

export const googleAdsReducer = (state: GoogleAdsState = initialGoogleAdsState, action: GoogleAdsActionTypes) => {
  switch (action.type) {
    case GoogleAdsActionsEnum.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
