import { Colors } from '@styles/tokens/colors/types';

export const colors: Colors = {
  gray: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#F5F5F5',
    400: '#F4F3F6',
    500: '#FBFBFC',
    600: '#EAEAEA',
    700: '#EBEBEB',
    800: '#DDDDDD',
    900: '#DCDCDC',
    1000: '#E1DEE8',
    1050: '#DEDEE8',
    1100: '#C9C5D4',
    1200: '#B0B0B0',
    1300: '#727272',
    1400: '#666173',
    1500: '#515051',
    1600: '#4D4C4D',
    1700: '#333333',
    1800: '#191919',
    1900: '#151516',
    2000: '#151515',
    2100: '#141415',
    2200: '#000000',
    2300: '#331B00',
    2400: '#303030',
  },
  blue: {
    200: '#CECDEE',
    300: '#B8B7E7',
    400: '#9290DA',
    500: '#5A57C6',
    600: '#37359C',
    700: '#232163',
    800: '#191847',
    880: '#010028',
  },
  red: {
    200: '#FFCCC2',
    500: '#ED4D4D',
    540: '#DD4B39',
    550: '#D72B2B',
    600: '#C11610',
    700: '#CC0049',
    1200: '#781714',
  },
  cristal: {
    100: '#E0ECFF',
    200: '#DDE7FC',
    360: '#B2E6FD',
    500: '#0055DB',
    600: '#0051D1',
    700: '#004bab',
  },
  beige: {
    200: '#F4DDC7',
  },
  orange: {
    200: '#DE8C12',
    500: '#F5850B',
    600: '#f5850A',
  },
  yellow: {
    200: '#EDBD40',
    300: '#F5BF00',
  },
  purple: {
    200: '#F2E4F5',
    500: '#6D2670',
    700: '#482671',
    800: '#5C1087',
  },
  green: {
    80: '#25D366',
    100: '#B7E0A9',
    200: '#BFE1AB',
    260: '#72A681',
    300: '#2F864C',
    400: '#899F40',
    500: '#348701',
    700: '#1A4C00',
  },
  pink: {
    200: '#BA2759',
  },
  alphaLight: {
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    80: 'rgba(255, 255, 255, 0.8)',
  },
};
